.do-not-paint-container {
  position: absolute;
  background-color: rgba(255, 255, 255, .9);
  left: 0;
  bottom: 0;
  width: 65px;
  height: 65px;
  border-radius: 10px;
  padding: 10px;
}

.not-painting {
  background: aquamarine;
}

.do-not-paint-button {
  height: 100% !important;
  width: 100% !important;
}
